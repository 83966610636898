<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else>
      <ProfileHubCriteriaTypePicker
        v-if="!hasCampaigns && !hasMapping"
        :mapping-type="computedTypeOptions"
        @launchMapping="launchMapping"
      />
      <ProfileHubCriteriaMappingList
        v-if="hasCampaigns && !hasMapping"
        :campaigns="campaigns"
        :mapping-type="computedTypeOptions"
        @postCurrentCampaign="postCurrentCampaign"
        @modifyMapping="modifyMapping"
        @addMapping="addMapping"
      />
      <ProfileHubCriteriaMappingIndividual
        v-if="!hasCampaigns && hasMapping"
        :mapping="currentMapping"
        :allCriteria="allCriteria"
        :currentCriteria="currentCriteria"
        :currentQuestions="currentMappingQuestions"
        :currentAnswers="currentMappingQuestionAnswers"
        :currentQA="currentQA"
        :newlyAddedMapping="newlyAddedMapping"
        :newQuestions="newQuestions"
        :currentMappingName="currentMappingName"
        @updateMapping="updateMapping"
        @deleteMapping="deleteMapping"
      />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ProfileHubCriteriaTypePicker from '@/components/ProfileHub/Criteria/TypePicker.vue'
import ProfileHubCriteriaMappingList from '@/components/ProfileHub/Criteria/MappingList.vue'
import ProfileHubCriteriaMappingIndividual from '@/components/ProfileHub/Criteria/MappingIndividual.vue'

export default {
  name: 'ProfileHubCriteriaMapping',
  components: {
    ProfileHubCriteriaTypePicker,
    ProfileHubCriteriaMappingList,
    ProfileHubCriteriaMappingIndividual,
  },
  data: () => ({
    hasCampaigns: false,
    hasMapping: false,
    newlyAddedMapping: false,
    mappingType: 'dynamic',
    currentMappingName: '',
    currentCriteria: [],
    allCriteria: [],
    sortedAnswers: [],
  }),

  async created() {
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      campaigns: state => state.profilehub.campaigns,
      mappingOptions: state => state.profilehub.mappingOptions,
      mappingCriteria: state => state.profilehub.mappingCriteria,
      currentBrand: state => state.backoffice.currentClient?.brandId,
      currentMapping: state => state.profilehub.currentMapping,
      currentMappingQuestions: state => state.profilehub.currentMappingQuestions,
      currentMappingQuestionAnswers: state => state.profilehub.currentMappingQuestionAnswers,
      currentQA: state => state.profilehub.currentQA,
      newQuestions: state => state.profilehub.newQuestions,
    }),
    computedTypeOptions() {
      if (this.mappingType === 'dynamic') {
        return {
          name: 'dynamic',
          origin: 'INTERNAL',
          type: 'V1_INTERFACE_SURVEY',
        }
      } else {
        return {
          name: 'salesforce',
          origin: 'EXTERNAL',
          type: 'V1_SALESFORCE',
        }
      }
    },
  },
  methods: {
    ...mapActions({
      setUpdating: 'backoffice/setUpdating',
      getCampaign: 'profilehub/getCampaign',
      postCampaign: 'profilehub/postCampaign',
      getMapping: 'profilehub/getMapping',
      getNewMapping: 'profilehub/getNewMapping',
      getMappingQuestion: 'profilehub/getMappingQuestion',
      postMappingQuestion: 'profilehub/postMappingQuestion',
      getMappingCriteria: 'profilehub/getMappingCriteria',
      getMappingQuestionAnswers: 'profilehub/getMappingQuestionAnswers',
      postMappingAnswer: 'profilehub/postMappingAnswer',
      deleteMappingQuestion: 'profilehub/deleteMappingQuestion',
      getQA: 'profilehub/getQA',
    }),

    async launchMapping() {
      this.setUpdating(true)
      await this.getCampaign({ type: this.computedTypeOptions.type, origin: this.computedTypeOptions.origin })
      this.setUpdating(false)
      this.hasCampaigns = true
    },

    async postCurrentCampaign(id, status) {
      this.setUpdating(true)
      let campaign = {
        reject: null,
        brand_id: this.currentBrand,
        type: this.computedTypeOptions.type,
        origin: this.computedTypeOptions.origin,
      }
      if (status === 'reject') {
        campaign.reject = 1
      } else {
        campaign.reject = 0
      }
      await this.postCampaign({ id: id, body: campaign })
      await this.launchMapping()
      this.setUpdating(false)
    },

    async modifyMapping(id) {
      this.setUpdating(true)
      await this.getMapping(id)
      await this.getMappingQuestion(id)
      await this.getMappingCriteria()
      this.currentCriteria = this.getCurrentGtrCriteria().concat(this.getCurrentCustomCriteria())
      await this.getAnswers()
      await this.getQA(this.currentMapping[0].feedback_template_id)
      this.getAllCriteria()
      this.activeSort()
      this.currentMappingName = this.currentMapping[0].name
      this.hasCampaigns = false
      this.hasMapping = true
      this.setUpdating(false)
    },

    async addMapping(name, feedback_template_id) {
      this.setUpdating(true)
      const newMappingId = await this.getNewMapping({ name: name, feedback_template_id: feedback_template_id })
      await this.getMapping(newMappingId)
      await this.getMappingQuestion(newMappingId)
      await this.getMappingCriteria()
      this.currentCriteria = this.getCurrentGtrCriteria().concat(this.getCurrentCustomCriteria())
      await this.getAnswers()
      await this.getQA(feedback_template_id)
      this.getAllCriteria()
      this.activeSort()
      this.newlyAddedMapping = true
      this.currentMappingName = this.currentMapping[0].name
      this.hasCampaigns = false
      this.hasMapping = true
      this.setUpdating(false)
    },

    getCurrentCustomCriteria() {
      const currentCriteria = this.mappingCriteria.custom.filter(criteria => {
        return this.currentMappingQuestions.some(question => {
          return question.id_segment_custom === criteria.id
        })
      })
      return currentCriteria
    },
    getCurrentGtrCriteria() {
      const currentCriteria = this.mappingCriteria.gtr.filter(criteria => {
        return this.currentMappingQuestions.some(question => {
          return question.id_segment_gtr === criteria.id
        })
      })
      return currentCriteria
    },
    getAllCriteria() {
      this.allCriteria = this.mappingCriteria.custom.concat(this.mappingCriteria.gtr)
    },

    activeSort() {
      let sortedCriteria = []
      this.allCriteria.forEach(criteria => {
        if (
          this.currentCriteria.some(currCriteria => {
            return currCriteria.id === criteria.id && currCriteria.origin === criteria.origin
          })
        ) {
          sortedCriteria.unshift(criteria)
        } else {
          sortedCriteria.push(criteria)
        }
      })
      this.allCriteria = sortedCriteria
    },

    async getAnswers() {
      const currentQuestionsId = this.currentMappingQuestions.filter(
        question => question.type === 'list_unique' || question.type === 'list_multiple'
      )
      Promise.all(currentQuestionsId.map(question => this.getMappingQuestionAnswers(question.id)))
    },

    async updateMapping(updatedMapping) {
      this.setUpdating(true)
      const questionArray = []
      const answerArray = []
      updatedMapping.forEach(updatedItem => {
        questionArray.push(this.postMappingQuestion(updatedItem.question))
      })
      await Promise.all(questionArray)
      const updatedAnswers = []
      updatedMapping.forEach(mapping => {
        if (mapping.answers) {
          mapping.answers.map(answer => {
            updatedAnswers.push({
              ...answer,
              gtr_mapping_transfer_question_id: this.getNewTransferId(answer),
            })
          })
        }
      })
      updatedAnswers.forEach(answer => {
        if (answer !== []) {
          answerArray.push(this.postMappingAnswer(answer))
        }
      })

      await Promise.all(answerArray)

      this.setUpdating(false)
      this.hasMapping = false
    },

    getNewTransferId(item) {
      const matchingQuestion = this.newQuestions.find(
        newQuestion => newQuestion.id === item.gtr_mapping_transfer_question_id
      )
      return matchingQuestion.gtr_mapping_transfer_question_id
    },

    async deleteMapping(criteria) {
      await this.deleteMappingQuestion({
        id: criteria.mapping.question.id,
        gtr_mapping_transfer_id: criteria.mapping.question.gtr_mapping_transfer_id,
      })
    },

    // Utile pour plus tard ?
    editAnswer(item, index, valueIndex) {
      const newAnswer = {
        default_value: '',
        external_field: item.label,
        gtr_mapping_transfer_question_id: this.newMapping[index].mapping.question.id,
        feedback_question_answer_id: this.getCreatedQuestionId(item),
        id_segment_custom_values: this.newMapping[index].criteria.values[valueIndex].body.id_segment_custom_values
          ? this.newMapping[index].criteria.values[valueIndex].id
          : null,
        id_segment_gtr_values: this.newMapping[index].criteria.values[valueIndex].body.id_segment_gtr_values
          ? this.newMapping[index].criteria.values[valueIndex].id
          : null,
        type: this.newMapping[index].criteria.values[valueIndex].body.type,
        updated: true,
      }
      this.newMapping[index].mapping.answers.push(newAnswer)
    },
  },
}
</script>
