var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-h-full tw-flex tw-flex-col"},[_c('UiContainer',[_c('div',{staticClass:"tw-flex tw-flex-col tw-flex-auto tw-gap-8 tw-justify-center tw-items-center tw-h-full"},[_c('div',[_c('UiTitle',{attrs:{"large":"","title":_vm.$t('profilehub.criteria.mapping.typePicker.title'),"centered":""}}),_c('UiSubtitle',{attrs:{"subtitle":_vm.$t('profilehub.criteria.mapping.typePicker.subtitle'),"centered":""}})],1),_c('v-item-group',{staticClass:"tw-flex tw-flex-row tw-justify-center tw-gap-8",attrs:{"value":_vm.mappingType,"mandatory":""},on:{"change":_vm.onChange}},[_c('v-item',{attrs:{"value":"dynamic"},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"profilehub-criteria-mapping-picker__card",class:{
                'tw-ring-2 tw-ring-primary': active,
                'tw-bg-black tw-bg-opacity-5': hover,
              },attrs:{"width":"300px"},on:{"click":toggle}},[(active)?_c('v-icon',{staticClass:"tw-absolute tw-top-2 tw-right-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e(),_c('v-icon',{attrs:{"size":72,"color":active ? 'primary' : null}},[_vm._v(_vm._s(_vm.icons.mdiShoppingOutline))]),_c('UiTitle',{staticClass:"tw-mt-2 tw-self-start",attrs:{"title":_vm.$t('profilehub.criteria.mapping.typePicker.dynamic.title'),"small":""}}),_c('UiSubtitle',{staticClass:"tw-text-left",attrs:{"subtitle":_vm.$t('profilehub.criteria.mapping.typePicker.dynamic.subtitle')}})],1)]}}],null,true)})]}}])}),_c('v-item',{attrs:{"value":"salesforce"},scopedSlots:_vm._u([{key:"default",fn:function({ active, toggle }){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"profilehub-criteria-mapping-picker__card",class:{
                'tw-ring-2 tw-ring-primary': active,
                'tw-bg-black tw-bg-opacity-5': hover,
              },attrs:{"width":"300px"},on:{"click":toggle}},[(active)?_c('v-icon',{staticClass:"tw-absolute tw-top-2 tw-right-2",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckCircle)+" ")]):_vm._e(),_c('v-icon',{attrs:{"size":72,"color":active ? 'primary' : null}},[_vm._v(_vm._s(_vm.icons.mdiSalesforce))]),_c('UiTitle',{staticClass:"tw-mt-2 tw-self-start",attrs:{"title":_vm.$t('profilehub.criteria.mapping.typePicker.salesforce.title'),"small":""}}),_c('UiSubtitle',{staticClass:"tw-text-left",attrs:{"subtitle":_vm.$t('profilehub.criteria.mapping.typePicker.salesforce.subtitle')}})],1)]}}],null,true)})]}}])})],1),_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.$t('button.next'))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(_vm._s(_vm.icons.mdiArrowRight))])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }