<template>
  <div class="profilehub-criteria-mapping-individual">
    <UiBanner>
      <template v-slot:content>
        <div class="tw-flex">
          <div>
            <UiTitle :title="$t('profilehub.criteria.mapping.individual.title')" large />
          </div>
        </div>
      </template>
    </UiBanner>
    <UiContainer class="tw-relative tw-h-full">
      <UiTitle :title="`&quot${currentMappingName}&quot - ${formatedDate(mapping[0].created_at, 'L LT')}`" />
      <div>
        <div v-for="(criteria, index) in newMapping" :key="index" class="tw-grid tw-grid-cols-2 tw-gap-2">
          <div class="tw-flex tw-items-start">
            <!-- Criteria -->
            <v-checkbox
              class=""
              v-model="selectedCriteria[index]"
              :value="criteria.criteria.label"
              @change="toggleCriteria(index, criteria)"
            ></v-checkbox>
            <div>
              <v-text-field
                class="profilehub-criteria-mapping-individual__text-field"
                disabled
                hide-details
                :value="criteria.criteria.label"
              ></v-text-field>
              <!-- Values -->
              <div v-if="hasValues(criteria.criteria) && criteria.criteria.values" class="tw-ml-6">
                <div
                  v-for="(value, valueIndex) in criteria.criteria.values"
                  :key="valueIndex"
                  class="tw-flex tw-items-center tw-mt-1"
                >
                  <v-icon class="tw-mt-3" x-small>{{ icons.mdiArrowRightBottom }}</v-icon>
                  <v-text-field disabled hide-details :value="value.label"></v-text-field>
                </div>
              </div>
            </div>
            <v-icon v-if="hasValues(criteria.criteria)" class="tw-mt-5 tw-flex tw-items-ends tw-ml-6" base>{{
              icons.mdiRayStartArrow
            }}</v-icon>
          </div>
          <!-- Questions -->
          <div v-if="criteria.qa && criteria.hasMapping" class="tw-mt-3 tw-flex tw-items-start">
            <div>
              <div class="tw-flex">
                <!--Truc à changer-->
                <v-select
                  v-if="criteria.qa"
                  :items="newMapping.filter(mapping => mapping.qa)"
                  :label="criteria.qa.label"
                  class="profilehub-criteria-mapping-individual__select"
                  item-text="qa.label"
                  item-value="qa.id"
                  dense
                  outlined
                  hide-details
                  solo
                  flat
                  return-object
                  :disabled="!newlyAddedMapping || newMapping.mapping.length === 0"
                ></v-select>
              </div>
              <!-- Answers -->
              <div v-if="criteria.qa.answers" class="tw-ml-6">
                <div
                  v-for="(answer, valueIndex) in criteria.qa.answers"
                  :key="valueIndex"
                  class="tw-flex tw-items-start tw-mt-3"
                >
                  <v-icon x-small class="tw-mt-3">{{ icons.mdiArrowRightBottom }}</v-icon>
                  <v-select
                    :items="criteria.qa.answers"
                    :label="answer.label"
                    item-text="label"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    solo
                    flat
                    :disabled="!newlyAddedMapping || newMapping.mapping.length === 0"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="criteria.edited">
            <div class="tw-mt-3 tw-flex">
              <v-select
                v-if="!isConstant[index]"
                :items="currentQA"
                label="Select a question"
                class="profilehub-criteria-mapping-individual__select"
                item-text="label"
                item-value="id"
                :value="currentQA.id"
                v-model="selectedQuestion[index]"
                dense
                outlined
                hide-details
                solo
                flat
                return-object
                @input="editQuestion($event, index)"
              ></v-select>
              <v-text-field
                v-else
                class="profilehub-criteria-mapping-individual__select"
                label="Enter the value"
                outlined
                dense
                hide-details
                solo
                flat
                @input="inputConstantValue($event, index)"
              ></v-text-field>

              <div>
                <v-checkbox
                  v-if="selectedQuestion[index] && !selectedQuestion[index].answers"
                  class="tw-ml-4 tw-mt-1"
                  v-model="isConstant[index]"
                  label="Constant"
                  hide-details
                ></v-checkbox>
              </div>
            </div>
            <div v-if="criteria.criteria.values" class="tw-ml-6">
              <div v-for="(answer, valueIndex) in criteria.criteria.values" :key="valueIndex">
                <div v-if="criteria.qa && criteria.qa.answers" class="tw-flex tw-mt-3">
                  <v-icon x-small class="tw-flex tw-mt-3">{{ icons.mdiArrowRightBottom }}</v-icon>
                  <v-select
                    v-if="!isOverwrite[index][valueIndex]"
                    :items="criteria.qa.answers"
                    label="Pick an answer"
                    item-text="label"
                    item-value="id"
                    dense
                    outlined
                    hide-details
                    solo
                    flat
                    return-object
                    @input="editAnswer($event, index, valueIndex)"
                  ></v-select>
                  <v-text-field
                    v-else
                    class="profilehub-criteria-mapping-individual__select"
                    label="Enter the value"
                    outlined
                    dense
                    hide-details
                    solo
                    flat
                    @input="inputOverwriteValue($event, index, valueIndex)"
                  ></v-text-field>
                  <v-checkbox
                    class="tw-ml-4"
                    v-model="isOverwrite[index][valueIndex]"
                    label="Overwrite"
                    dense
                    hide-details
                  ></v-checkbox>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="criteria.qa !== null" class="tw-mt-3 tw-flex">
            <v-text-field
              class="profilehub-criteria-mapping-individual__select"
              outlined
              :label="getCriteriaDefaultValue(criteria)"
              dense
              hide-details
              solo
              flat
              disabled
            ></v-text-field>
          </div>
        </div>
      </div>
      <UiActions large centered>
        <v-btn type="submit" rounded color="primary" @click="onSubmitMapping()"> {{ $t('button.send') }} </v-btn>
      </UiActions>
    </UiContainer>
  </div>
</template>

<script>
import { mdiPlus, mdiArrowLeftRight, mdiArrowRightBottom, mdiRayStartArrow } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiActions from '@/components/UI/Actions.vue'
import { formatedDate } from '@/utils/date.util'
import { clone } from '@/utils/utilities.util'

export default {
  name: 'ProfileHubCriteriaMappingIndividual',
  components: {
    UiBanner,
    UiTitle,
    UiContainer,
    UiActions,
  },
  props: {
    mapping: {
      type: Array,
      required: true,
    },
    currentCriteria: {
      type: Array,
      required: true,
    },
    allCriteria: {
      type: Array,
      required: true,
    },
    currentAnswers: {
      type: Array,
      required: true,
    },
    currentQuestions: {
      type: Array,
      required: true,
    },
    currentQA: {
      type: Array,
      required: true,
    },
    newlyAddedMapping: {
      type: Boolean,
      required: true,
    },
    currentMappingName: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    formatedDate,
    isConstant: [],
    isOverwrite: [],
    currentCriteriaValues: [],
    editMode: false,
    selectedCriteria: [],
    mappingQuestions: [],
    changedQuestion: false,
    isConstantable: [],
    icons: {
      mdiPlus,
      mdiArrowLeftRight,
      mdiArrowRightBottom,
      mdiRayStartArrow,
    },
    newMapping: null,
    matchedQA: [],
    pickedQuestion: [],
    selectedQuestion: [],
  }),

  created() {
    this.selectedCriteria = this.allCriteria
      .filter(criteria => {
        return this.isActive(criteria)
      })
      .map(criteria => criteria.label)

    this.createMapping()
    this.allCriteria.forEach(() => {
      this.isConstantable.push(false)
      this.selectedQuestion.push(null)
      this.isConstant.push(null)
      this.isOverwrite.push([])
    })
  },

  methods: {
    createMapping() {
      this.newMapping = this.allCriteria
      this.newMapping = this.newMapping.map(criteria => {
        return {
          criteria,
          mapping: {
            question: this.assignQuestion(criteria) || null,
            answers: null,
          },
          qa: null,
          edited: false,
        }
      })
      this.newMapping = this.newMapping.map(mappingItem => {
        return {
          ...mappingItem,
          mapping: {
            ...mappingItem.mapping,
            answers: this.findQuestions(mappingItem),
          },
          qa: this.assingQA(mappingItem),
          hasMapping: mappingItem.mapping.question ? true : false,
          isDeletable: mappingItem.mapping.question ? true : false,
        }
      })
    },
    assingQA(item) {
      if (item.mapping.question) {
        return this.currentQA.find(
          question =>
            question.body.feedback_question_id === item.mapping.question.feedback_question_id ||
            question.body.v2_feedback_question_key === item.mapping.question.v2_feedback_question_key
        )
      }
      return null
    },
    findQuestions(item) {
      return this.currentAnswers.find(answers => {
        if (item.mapping.question) {
          return answers[0].gtr_mapping_transfer_question_id === item.mapping.question.id
        }
        return false
      })
    },
    assignQuestion(criteria) {
      return this.currentQuestions.find(question => {
        if (criteria.origin === 'gtr') {
          return criteria.id === question.id_segment_gtr
        } else {
          return criteria.id === question.id_segment_custom
        }
      })
    },
    // Utile pour plus tard ?
    assignAnswers(question) {
      return this.currentAnswers.find(answer => answer[0].gtr_mapping_transfer_question_id === question.id)
    },

    // Utile pour plus tard ?
    changeCriteria(item) {
      this.editMode = true
      this.currentCriteriaValues = []
      if (item.values) {
        this.currentCriteriaValues = item.values
      } else {
        this.currentCriteriaValues = []
      }
      this.editMode = false
    },
    isActive(item) {
      return this.currentCriteria.some(criteria => item.id === criteria.id && item.origin === criteria.origin)
    },
    hasValues(item) {
      return this.selectedCriteria.some(criteria => item.label === criteria)
    },
    editQuestion(item, index) {
      const newItem = clone(item)
      if (item.answers) {
        this.isConstantable[index] = false
        newItem.answers.unshift({ body: null, id: null, label: '' })
      } else {
        this.isConstantable[index] = true
      }
      this.newMapping[index] = {
        ...this.newMapping[index],
        qa: newItem,
        mapping: {
          question: {
            gtr_mapping_transfer_id: this.mapping[0].id,
            default_value: null,
            external_field: item.label,
            external_code: null,
            v2_feedback_question_key: item.body.v2_feedback_question_key ? item.id : null,
            feedback_question_id: item.body.feedback_question_id ? item.id : null,
            type: this.newMapping[index].criteria.body.type,
            id_segment_gtr:
              this.newMapping[index].criteria.origin === 'gtr'
                ? this.newMapping[index].criteria.body.id_segment_gtr
                : null,
            id_segment_custom:
              this.newMapping[index].criteria.origin === 'custom'
                ? this.newMapping[index].criteria.body.id_segment_custom
                : null,
          },
          updated: true,
          answers: [],
        },
      }
    },
    editAnswer(item, index, valueIndex) {
      const newAnswer = {
        default_value: null,
        external_field: item.label,
        feedback_question_answer_id: item.id,
        id_segment_custom_values: this.newMapping[index].criteria.values[valueIndex].body.id_segment_custom_values
          ? this.newMapping[index].criteria.values[valueIndex].id
          : null,
        id_segment_gtr_values: this.newMapping[index].criteria.values[valueIndex].body.id_segment_gtr_values
          ? this.newMapping[index].criteria.values[valueIndex].id
          : null,
        type: this.newMapping[index].criteria.values[valueIndex].body.type,
        updated: true,
      }
      this.newMapping[index].mapping.answers.push(newAnswer)
    },
    inputConstantValue(item, index) {
      this.newMapping[index] = {
        ...this.newMapping[index],
        mapping: {
          question: {
            gtr_mapping_transfer_id: this.mapping[0].id,
            default_value: item,
            external_field: null,
            external_code: null,
            isConstant: true,
            type: this.newMapping[index].criteria.body.type,
            id_segment_gtr:
              this.newMapping[index].criteria.origin === 'gtr'
                ? this.newMapping[index].criteria.body.id_segment_gtr
                : null,
            id_segment_custom:
              this.newMapping[index].criteria.origin === 'custom'
                ? this.newMapping[index].criteria.body.id_segment_custom
                : null,
          },
          updated: true,
        },
      }
    },
    inputOverwriteValue(item, index, valueIndex) {
      this.newMapping[index].mapping.answers[valueIndex] = {
        ...this.newMapping[index].mapping.answers[valueIndex],
        default_value: item,
        external_field: null,
        isOverwrite: true,
        gtr_mapping_transfer_question_id: this.newMapping[index].mapping.question.id,
        id_segment_custom_values: this.newMapping[index].criteria.values[valueIndex].body.id_segment_custom_values
          ? this.newMapping[index].criteria.values[valueIndex].id
          : null,
        id_segment_gtr_values: this.newMapping[index].criteria.values[valueIndex].body.id_segment_gtr_values
          ? this.newMapping[index].criteria.values[valueIndex].id
          : null,
        type: this.newMapping[index].criteria.values[valueIndex].body.type,
      }
    },
    toggleCriteria(index, criteria) {
      if (this.selectedCriteria[index] !== null) {
        this.newMapping[index] = {
          ...this.newMapping[index],
          edited: true,
        }
      } else if (!this.newMapping[index].deleted) {
        this.newMapping[index] = {
          ...this.newMapping[index],
          mapping: null,
          qa: null,
          hasMapping: false,
          edited: false,
          deleted: true,
        }
        if (this.newMapping[index].isDeletable) {
          this.$emit('deleteMapping', criteria)
        }
      }
    },

    getCriteriaDefaultValue(criteria) {
      if (criteria.mapping.question) {
        return criteria.mapping.question.default_value
      } else {
        return ''
      }
    },

    onSubmitMapping() {
      const updatedMapping = this.newMapping.filter(mapping => mapping.mapping.updated).map(mapping => mapping.mapping)
      this.$emit('updateMapping', updatedMapping)
    },
  },
}
</script>

<style lang="scss" scoped>
.profilehub-criteria-mapping-individual {
  &__text-field {
    width: 250px;
  }

  &__select {
    width: 290px;
  }
}
</style>
