<template>
  <div class="profilehub-criteria-mapping-list">
    <UiBanner>
      <template v-slot:content>
        <div class="tw-flex">
          <div>
            <UiTitle :title="$t('profilehub.criteria.mapping.list.title')" large />
          </div>
        </div>
      </template>
    </UiBanner>
    <UiContainer class="tw-relative tw-h-full" no-padding>
      <div>
        <v-tabs v-model="tab">
          <v-tab class="tw-ml-6" href="#tab-available" :disabled="campaigns.available.length === 0">
            <v-badge :content="campaigns.available.length" inline :value="campaigns.available.length">
              {{ $t('profilehub.criteria.mapping.list.tabs.available') }}
            </v-badge>
          </v-tab>
          <v-tab href="#tab-assigned" :disabled="campaigns.assigned.length === 0">
            <v-badge :content="campaigns.assigned.length" inline :value="campaigns.assigned.length">
              {{ $t('profilehub.criteria.mapping.list.tabs.assigned') }}
            </v-badge>
          </v-tab>
          <v-tab href="#tab-rejected" :disabled="campaigns.rejected.length === 0">
            <v-badge :content="campaigns.rejected.length" inline :value="campaigns.rejected.length">
              {{ $t('profilehub.criteria.mapping.list.tabs.rejected') }}
            </v-badge>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="tw-bg-transparent tw-p-6">
          <v-tab-item :value="'tab-available'">
            <div class="tw-grid sm:tw-grid-cols-3 tw-gap-6">
              <v-card v-for="(campaign, index) in campaigns.available" :key="`campaign-${index}`">
                <v-card-title class="tw-text-base">{{ campaign.campaign_name }}</v-card-title>
                <v-card-subtitle>{{ formatedDate(campaign.created, 'L') }}</v-card-subtitle>
                <div class="tw-flex tw-p-4">
                  <v-btn
                    color="primary"
                    rounded
                    @click="addMapping(campaign.campaign_name, campaign.feedback_template_id)"
                    small
                  >
                    <v-icon left>{{ icons.mdiPlus }}</v-icon>
                    {{ $t('profilehub.criteria.mapping.list.actions.available.add') }}
                  </v-btn>
                  <v-spacer />
                  <v-btn color="red" outlined rounded @click="ignoreCampaign(campaign.campaign_id, 'reject')" small>
                    <v-icon left>{{ icons.mdiClose }}</v-icon>
                    {{ $t('profilehub.criteria.mapping.list.actions.available.ignore') }}
                  </v-btn>
                </div>
              </v-card>
            </div>
          </v-tab-item>
          <v-tab-item :value="'tab-assigned'">
            <div class="tw-grid sm:tw-grid-cols-3 tw-gap-6">
              <v-card
                class="tw-flex tw-flex-col"
                v-for="(campaign, index) in campaigns.assigned"
                :key="`campaign-${index}`"
              >
                <v-card-title class="tw-text-base">{{ campaign.campaign_name }}</v-card-title>
                <v-card-subtitle class="tw-flex tw-items-center">
                  <span>{{ formatedDate(campaign.created, 'L') }}</span>
                  <v-chip
                    class="tw-ml-4"
                    small
                    color="orange"
                    text-color="white"
                    v-if="campaign.mapping_status === 'draft'"
                  >
                    <v-icon small left>{{ icons.mdiCogPauseOutline }}</v-icon>
                    {{ $t('profilehub.criteria.mapping.list.assigned.pending') }}</v-chip
                  >
                </v-card-subtitle>
                <div class="tw-flex tw-p-4 tw-mt-auto">
                  <v-btn color="primary" rounded @click="modifyMapping(campaign.mapping_transfer_id)" small>
                    <v-icon left>{{ icons.mdiPencil }}</v-icon>
                    {{ $t('profilehub.criteria.mapping.list.actions.assigned.add') }}
                  </v-btn>
                </div>
              </v-card>
            </div>
          </v-tab-item>
          <v-tab-item :value="'tab-rejected'">
            <div class="tw-grid sm:tw-grid-cols-3 tw-gap-6">
              <v-card
                class="tw-flex tw-flex-col"
                v-for="(campaign, index) in campaigns.rejected"
                :key="`campaign-${index}`"
              >
                <v-card-title class="tw-text-base">{{ campaign.campaign_name }}</v-card-title>
                <v-card-subtitle class="tw-flex tw-items-center">
                  <span>{{ formatedDate(campaign.created, 'L') }}</span>
                </v-card-subtitle>
                <div class="tw-flex tw-p-4 tw-mt-auto">
                  <v-btn color="primary" rounded @click="reatributeCampaign(campaign.campaign_id, 'activate')" small>
                    <v-icon left>{{ icons.mdiPlusBoxOutline }}</v-icon>
                    {{ $t('profilehub.criteria.mapping.list.actions.rejected.add') }}
                  </v-btn>
                </div>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </UiContainer>
  </div>
</template>

<script>
import { mdiPlus, mdiClose, mdiPencil, mdiCogPauseOutline, mdiPlusBoxOutline } from '@mdi/js'
import UiTitle from '@/components/UI/Title.vue'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import { formatedDate } from '@/utils/date.util'

export default {
  name: 'ProfileHubCriteriaMappingList',
  components: {
    UiBanner,
    UiTitle,
    UiContainer,
  },
  props: {
    mappingType: {
      type: Object,
      required: true,
    },
    campaigns: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    tab: null,
    formatedDate,
    icons: {
      mdiPlus,
      mdiClose,
      mdiPencil,
      mdiCogPauseOutline,
      mdiPlusBoxOutline,
    },
  }),
  computed: {},
  methods: {
    addMapping(name, feedback_template_id) {
      this.$emit('addMapping', name, feedback_template_id)
    },
    ignoreCampaign(id, reject) {
      this.$emit('postCurrentCampaign', id, reject)
    },
    modifyMapping(id) {
      this.$emit('modifyMapping', id)
    },
    reatributeCampaign(id, activate) {
      this.$emit('postCurrentCampaign', id, activate)
    },
  },
}
</script>

<style lang="scss">
.profilehub-criteria-mapping-list {
  @apply tw-flex tw-h-full tw-flex-col;

  &__table {
    @apply tw-h-full tw-flex tw-flex-col;
  }

  &__table tbody tr:hover {
    cursor: pointer;
  }
}
</style>
